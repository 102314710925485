import { ContentItem } from "./Components/types";

// MOVE
import moveheader from './Images/Pon Move/header.webp';
import moveOne from './Images/Pon Move/1.webp';
import moveTwo from './Images/Pon Move/2.webp';
import moveThree from './Images/Pon Move/3.webp';
import moveUserVid from './Images/Pon Move/Casevideo.mp4';

// THE SCORE
import thescoreheader from './Images/The Score/header.webp';
import scoreOne from './Images/The Score/1.webp';
import scoreThree from './Images/The Score/3.webp';
import scoreFour from './Images/The Score/4.webp';
import scoreSix from './Images/The Score/6.webp';
import scoreVid from './Images/The Score/the-score.webm';

// Cargo  Captain
import cargocaptainheader from './Images/Cargo Captain/Compressed/1.webp';
import ccTwo from './Images/Cargo Captain/Compressed/2.webp';
import ccThree from './Images/Cargo Captain/Compressed/3.webp';
import ccFour from './Images/Cargo Captain/Compressed/4.webp';
import ccVideo from './Images/Cargo Captain/Compressed/cargo-captain.webm';

// Doritos
import doritosOne from './Images/Doritos/1_upscaled.png';
import doritosTwo from './Images/Doritos/2_upscaled.png';
import doritosHeader from './Images/Doritos/3_upscaled.png';
import doritiosVid from './Images/Doritos/doritos-1.webm';
import doritiosVid2 from './Images/Doritos/doritos-2.webm';

// Duckpocalypse
import duckTwo from './Images/Duckpocalypse/1_upscaled.png';
import duckFour from './Images/Duckpocalypse/5_upscaled.png';
import duckVid from './Images/Duckpocalypse/duckpocalypse.webm';

// Polestar
import polestarheader from './Images/Polestar 2/header.png';
import poleOne from './Images/Polestar 2/2.webp';
import poleFive from './Images/Polestar 2/5.webp';
import poleVid from './Images/Polestar 2/polestar.mp4';

// Escape room
import escaperoomheader from './Images/Escape room the game/header_upscaled.png';
import escapeOne from './Images/Escape room the game/1.webp';
import escapeTwo from './Images/Escape room the game/2_upscaled.png';

const moveText = "Move, shaping the smart city is a project made for the Move Museum, part of Pon. It is an AR exhibit where children can use the power of AR to build a city of the future. An iPad is used to scan physical blocks. These blocks can then be transformed into buildings. The aim of the project is to learn what is takes to build a sustainable city.";

const moveContributions = [
    'Team lead - Detailed planning of project together with project manager',
    'Team lead - Guide sprint meetings',
    'Team lead - Discuss ideas with creative and UX designer',
    'Development - Creating a grid based traffic system',
    'Development - Creating a block based building system',
    'Development - Creating a fadeable environment with LOD levels and a dissolve shader',
    'Development - Various optimizations for mobile devices',
    'Design - Contribute to UX flow together with UX designer',
    'Design - Create the logic flow for a block based building system',
    'Guiding intern on this project'
];

export const move: ContentItem = {
    title: "Move",
    chips: ['AR', 'Game', 'Museum', 'iPad', 'IOS'],
    about: moveText,
    headerImage: moveheader,
    pageName: "/move",
    contributions: moveContributions,
    visualItems: [
        {
            path: moveUserVid,
            type: 'mutedvideo'
        },
        {
            path: moveheader,
            type: 'image'
        },
        {
            path: moveOne,
            type: 'image'
        },
        {
            path: moveTwo,
            type: 'image'
        },
        {
            path: moveThree,
            type: 'image'
        }
    ]
}

export const theScore: ContentItem = {
    title: "The Score",
    headerImage: thescoreheader,
    chips: ['Live Music', 'Metropole', 'Game', 'Multiplayer', 'Android', 'IOS'],
    about: "The Score is a project made in contribution with Wildvreemd and the Metropole Orkest. The aim of the project is to create new forms of interaction during live concerts. During the concert, the audience play mobile games. Playing the games influences the live music that is played by the Metropole Orchestra.",
    pageName: "/thescore",
    contributions:
        [
            'Unity lead - Advice on scope and planning of the project',
            'Unity lead - Help other developer with technical questions',
            'Design - Help set out scope of the application',
            'Development - Creating a multiplayer interaction system, based on Firebase Realtime Database',
            'Development - Creating a system that allows to schedule and playback audio files in sync on several mobile devices',
            'Development - Develop several parts of mini games that can be played throughout the concert'
        ],
    visualItems: [
        {
            path: scoreVid,
            type: 'video'
        },
        {
            path: scoreOne,
            type: 'image'
        },
        {
            path: scoreThree,
            type: 'image'
        },
        {
            path: scoreFour,
            type: 'image'
        },
        {
            path: scoreSix,
            type: 'image'
        }
    ]
}

export const cargoCaptain: ContentItem = {
    title: "Cargo Captain",
    headerImage: cargocaptainheader,
    chips: ['Game', 'Low Poly', 'Shipping', 'Android', 'IOS'],
    pageName: "/cargocaptain",
    about: "Together with my brother, we created Cargo Captain. It is a casual shipping game made for mobile that takes place in a group of islands. Distributed across the islands, harbours have cargo for you to ship across the ocean. Delivering this cargo eans you in-game currency that will allow you to upgrade ships, and purchase bigger ships.",
    visualItems: [
        {
            path: ccVideo,
            type: 'mutedvideo'
        },
        {
            path: ccTwo,
            type: 'image'
        },
        {
            path: ccThree,
            type: 'image'
        },
        {
            path: ccFour,
            type: 'image'
        },
        {
            path: cargocaptainheader,
            type: 'image'
        },
    ],
    contributions:
        [
            'Design - Create multiple paper and digital prototypes to set out the scope of the game',
            'Design - Creating a full game design for the game',
            'Design - Create UX and UI for the game',
            'Development - Creating a leveling system',
            'Development - Creating logic for the in-game shop',
            'Development - Create upgrade systems for ships',
            'Development - Create customizability systems for ships',
            'Development - Create a save system for in between game sessions',
            'Development - General development on the game'
        ],
}

export const doritos: ContentItem = {
    title: "Doritos VR battle",
    headerImage: doritosHeader,
    chips: ['VR', 'Game', 'Porting', 'Android', 'IOS'],
    pageName: '/doritosvrbattle',
    about: "Doritos VR Battle is made as an ad campaign for Doritos where influencers played the game. The campaign was such a success that we made a Steam and Mobile version of the game. I have been working on porting the Steam version to Android and iOS.",
    visualItems: [
        {
            path: doritiosVid,
            type: 'mutedvideo'
        },
        {
            path: doritosHeader,
            type: 'image'
        },
        {
            path: doritosOne,
            type: 'image'
        },
        {
            path: doritosTwo,
            type: 'image'
        },
        {
            path: doritiosVid2,
            type: 'mutedvideo'
        },
    ],
    contributions: [
        'Team planning - Creating a planning for optimizing the game for mobile',
        'Development - Creating a multiplayer scoreboard system',
        'Development - Creating a share system for Facebook which allows the user to share action shots from in-game',
        'Development - Porting the interaction system from HTC-Vive to Google Cardboard',
        'Development - Optimizing the game so it can run on low-end mobile devices'
    ],
}

export const duckpocalypse: ContentItem = {
    title: "Duckpocalypse",
    headerImage: duckTwo,
    chips: ['VR', 'Game', 'Porting', 'Low poly', 'Android', 'IOS'],
    pageName: '/duckpocalypse',
    about: "Duckpocalypse is a game that was originally made for the HTC Vive. I helped porting the game to Android, iOS and GearVR.",
    visualItems: [
        {
            path: duckVid,
            type: 'mutedvideo'
        },
        {
            path: duckTwo,
            type: 'image'
        },
        {
            path: duckFour,
            type: 'image'
        }
    ],
    contributions: [
        'Team planning - Creating a planning for optimizing the game for mobile',
        'Development - Creating interaction for Google cardboard instead of steam headset with controllers',
        'Development - Optimizing the game so it can run on low-end mobile devices'
    ],
}

export const polestarTwo: ContentItem = {
    title: "Polestar 2",
    headerImage: polestarheader,
    chips: ['AR', 'Experience', 'CAR', 'iPad'],
    pageName: '/polestar2',
    about: "How do you show unique features of a car that are hard to reach? I have been working on a showroom experience where the user is guided around the Polestar 2 with AR. At different points around the car, features are explained, such as the ADAS system.",
    visualItems: [
        {
            path: poleVid,
            type: 'video'
        },
        {
            path: poleOne,
            type: 'image'
        },
        {
            path: poleFive,
            type: 'image'
        }
    ],
    contributions: [
        'Team lead - detailed planning of project together with project manager and guiding development team',
        'Team lead - guide sprint meetings',
        'Design - Create a story flow system that guides you around the car',
        'Development - Creating a story flow system which guides you around the car',
        'Development - Creating a system which explains features of the car by animations',
        'Development - Creating fade transitions with shadergraph which take you from the \'real\' world to the digital world'
    ],
}

export const escapeRoomTheGame: ContentItem = {
    title: "Escaperoom The Game",
    headerImage: escaperoomheader,
    chips: ['Puzzle', 'VR', 'Game', 'IOS', 'Android'],
    pageName: '/escaperoomthegame',
    about: "Escape room the game was made for Identity Games, boardgame company. They have been creating board Escaperoom games, and wanted a new layer, in the form of VR.",
    visualItems: [
        {
            path: escapeOne,
            type: 'image'
        },
        {
            path: escaperoomheader,
            type: 'image'
        },
        {
            path: escapeTwo,
            type: 'image'
        }
    ],
    contributions: [
        'Team planning - detailed planning of project together with project manager',
        'Development - Creating VR levels',
        'Development - Creating VR puzzles',
        'Development - Creating a system where downloadable content can be served in order to keep the original download size of the app small',
        'Development - Optimizing the game so it can run on low end devlices',
        '3D Design - Creating lighting conditions and lightbake in Maya for the Demo level',
    ],
}